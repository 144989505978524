import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Listing, ListingModel, ListingResults, ProviderE } from '../models/listing.model';
import { FooterService } from './footer.service';
import { UrlParserService } from './url-parser.service';

const _apiMaper = {
  minPrice: 'ListPrice ge',
  maxPrice: 'ListPrice le',
  minBedrooms: 'BedroomsTotal ge',
  minBathrooms: 'BathroomsTotalInteger ge',
  minSqFt: 'LotSizeArea ge',
  maxSqFt: 'LotSizeArea le',
  minLotSize: 'LotSizeAcres ge',
  minYearBuilt: 'YearBuilt ge',
  maxYearBuilt: 'YearBuilt le',
  city: 'PostalCity eq',
  state: 'StateOrProvince eq',
};

const _orderMapper = {
  '-price': 'ListPrice desc',
  '+price': 'ListPrice asc',
  '-list_date': 'ModificationTimestamp desc',
  '+list_date': 'ModificationTimestamp asc',
  '+bedrooms': 'BedroomsTotal asc',
  '+bathrooms': 'BathroomsTotalInteger asc',
  '-square_feet': 'LotSizeArea desc',
  '-year_built': 'YearBuilt desc',
  default: 'ModificationTimestamp desc',
};

const _orderMapper2 = {
  '-list_date': 'listDate',
  '-price': 'price',
  '+price': 'price',
  '-bedrooms': 'bedrooms',
  '-bathrooms': 'bathrooms',
  '-square_feet': 'squareFeet',
  '-year_built': 'yearBuilt',
};

@Injectable({
  providedIn: 'root',
})
export class ListHubService {
  urlListhub: string = '';
  keyListhub: string = '';

  listhubAccessToken = '';
  hubAPIKey: string;

  constructor(
    private footerService: FooterService,
    private _http: HttpClient,
    private urlParserService: UrlParserService
  ) {
    this.urlListhub = environment.HUBAPI_BASE_URL + '/listhub-api';
    this.hubAPIKey = environment.HUB_API_KEY;
  }

  get provider() {
    return environment.API_TYPE;
  }

  private _getDataFromListhubDB(data: ListingResults): ListingModel {
    return {
      pageNumber: parseInt(data.page.toString()),
      elementsPerPage: data.listings.length,
      totalElements: data.total_elements,
      totalPages: Math.floor((data.total_elements - 1) / 20) + 1,
      listings: data.listings,
      provider: ProviderE.LISTHUB,
    };
  }

  getListingById(id: string) {
    let headers = new HttpHeaders();
    headers = headers.append('x-api-key', this.hubAPIKey);

    return this._http.get<Listing>(this.urlListhub + '/listings/' + id, {
      headers: headers,
    });
  }

  getListingsByIds(ids: string[], sort: string) {
    let headers = new HttpHeaders();
    headers = headers.append('x-api-key', this.hubAPIKey);

    return this._http.post<Listing[]>(
      this.urlListhub + '/listings?sort=' + encodeURIComponent(sort),
      { ids },
      {
        headers: headers,
      }
    );
  }

  getSimilarListingsById(listing: Listing, tolerance = 0.2) {
    const { listingId, price, bedrooms, bathrooms, propertyType, propertySubType, city, state } = listing;

    const priceNum = Number(price);
    const beds = bedrooms || 0;
    const baths = bathrooms || 0;

    let params = new HttpParams();
    let query = this.urlParserService.urlObject;
    for (let key in query) {
      params = params.append(key, query[key]);
    }
    params = params.append('city', city!);
    params = params.append('state', state!);
    params = params.append('count', '3');
    params = params.append('price', price!);
    params = params.append('minPrice', JSON.stringify(Math.round(priceNum - priceNum * tolerance)));
    params = params.append('maxPrice', JSON.stringify(Math.round(priceNum + priceNum * tolerance)));
    params = params.append('minBedrooms', JSON.stringify(Math.round(beds - beds * tolerance)));
    params = params.append('propertyType', propertyType!);
    params = params.append('propertySubType', propertySubType || '');
    params = params.append('minBathrooms', JSON.stringify(Math.round(baths - baths * tolerance)));
    params = params.append('listingId', listingId!);

    return this._http.get<Listing[]>(this.urlListhub + '/similarListings', {
      params: params,
    });
  }

  getListings() {
    // Clear MLS data and full listing url data in footer
    this.footerService.setMlsIcon('');
    this.footerService.setMlsInfo('');
    this.footerService.setListingUrl('');

    let headers = new HttpHeaders();
    headers = headers.append('x-api-key', this.hubAPIKey);

    let params = new HttpParams();
    params = params.append('$count', 'true');

    let query = this.urlParserService.urlObject;
    for (let key in query) {
      params = params.append(key, query[key]);
    }
    if (!params.get('page')) {
      params = params.append('page', '1');
    }

    return this._http
      .get<ListingResults>(this.urlListhub + '/listings', {
        params,
        headers,
      })
      .pipe(map((res) => this._getDataFromListhubDB(res)));
  }

  getNearByListings(latitude: number | string, longitude: number | string, distance: number | string) {
    let headers = new HttpHeaders();
    headers = headers.append('x-api-key', this.hubAPIKey);

    let params = new HttpParams();
    params = params.append('$count', 'true');
    params = params.append('$top', '5');
    params = params.append('latitude', latitude);
    params = params.append('longitude', longitude);
    params = params.append('distance', distance);

    let query = this.urlParserService.urlObject;
    for (let key in query) {
      params = params.append(key, query[key]);
    }
    if (!params.get('page')) {
      params = params.append('page', '1');
    }

    return this._http
      .get<ListingResults>(this.urlListhub + '/listings', {
        params,
        headers,
      })
      .pipe(map((res) => this._getDataFromListhubDB(res)));
  }

  getFavoritedListings(ids: string[], sort: string) {
    if (ids.length == 0) return of(null);
    return this.getListingsByIds(ids, sort);
  }

  searchLocations(str: string) {
    const url = environment.HUBAPI_BASE_URL + '/listhub-api/search-address';
    return this._http.get(url, {
      params: {
        input: str,
      },
    });
  }

  // private _sortByKey<T>(array: Array<T>, key: keyof typeof _orderMapper2): Array<T> {
  //   var first = key.charAt(0);
  //   // var format_key = key.substring(1); //remove first character
  //   var format_key = _orderMapper2[key];
  //   return array.sort((a: any, b: any) => {
  //     var x = a[format_key];
  //     var y = b[format_key];
  //     if (first === '+') {
  //       //low to high
  //       return x < y ? -1 : x > y ? 1 : 0;
  //     } else {
  //       //default high to low
  //       return x > y ? -1 : x < y ? 1 : 0;
  //     }
  //   });
}
