import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, forkJoin, map, switchMap, of, throwError } from 'rxjs';

import { environment } from 'src/environments/environment';
import { Listing, ListingModel } from '../models/listing.model';
import { FooterService } from './footer.service';
import { UrlParserService } from './url-parser.service';

@Injectable({
  providedIn: 'root',
})
export class RealStaqService {
  distance = '1000mi';

  key = '';
  mlsSearchApiUrl = '';
  schoolApiUrl = '';
  demographicsApiUrl = '';
  neighborhoodsApiUrl = '';
  valuationsApiUrl = '';
  propertiesApiUrl = '';

  headers = new HttpHeaders();

  constructor(
    private _http: HttpClient,
    private urlParserService: UrlParserService,
    private footerService: FooterService
  ) {
    this.key = environment.MLS_API_KEY;
    this.mlsSearchApiUrl = environment.MLS_API_BASE_URL + '/api/mls-search';
    this.schoolApiUrl = environment.MLS_API_BASE_URL + '/api/schools/v1/grouped-schools';
    this.demographicsApiUrl = environment.MLS_API_BASE_URL + '/api/demographics/v1/demographics';
    this.neighborhoodsApiUrl = environment.MLS_API_BASE_URL + '/api/neighborhoods/v1/search-area';
    this.valuationsApiUrl = environment.MLS_API_BASE_URL + '/api/property-info/v1/valuations';
    this.propertiesApiUrl = environment.MLS_API_BASE_URL + '/api/property-info/v1/properties';

    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('x-api-key', this.key);
    this.headers = headers;
  }

  get provider() {
    return environment.API_TYPE;
  }

  private _getDataFromRealStaqListing(data: any, mls: any): Listing {
    // Determine agent info
    let agentFullName = '';
    let agentEmail = '';
    let agentPreferredPhone = '';
    let agentOfficePhone = '';
    let agentOfficeName = '';
    if (data.agents.listing_agent) {
      const agent = data.agents.listing_agent;
      agentEmail = agent.email;
      agentFullName = agent.name;
      agentPreferredPhone = agent.phone;
      if (agent.office) {
        agentOfficePhone = agent.office.phone;
        agentOfficeName = agent.office.name;
      }
    } else if (data.agents.co_listing_agent) {
      const agents = data.agents.co_listing_agent;
      agentEmail = agents.email;
      agentFullName = agents.name;
      agentPreferredPhone = agents.phone;
      if (agents.office) {
        agentOfficePhone = agents.office.phone;
        agentOfficeName = agents.office.name;
      }
    }

    const listing: Listing = {
      agentFullName,
      agentEmail,
      agentPreferredPhone,
      agentOfficePhone,
      agentOfficeName,
      address: data.address,
      bathrooms: data.bathrooms,
      bedrooms: data.bedrooms,
      city: data.city,
      country: data.country,
      description: data.description,
      id: data.id,
      lotSizeArea: data.lot_size,
      lotSizeUnits: 'acres',
      photos: data.photos.map((p: any) => p.large),
      listingId: data.id,
      modificationTimestamp: data.list_date,
      latitude: data.location && data.location[1],
      longitude: data.location && data.location[0],
      mlsId: data.mls_listing_id,
      mlsBrokerName: mls && mls.name,
      mlsDisclaimer: mls && mls.compliance_text,
      mlsLogo: mls && (mls.idx_logo || mls.idx_logo_small),
      price: data.price,
      propertyType: data.property_type,
      propertySubType: '',
      squareFeet: data.square_feet,
      state: data.state,
      standardStatus: data.status,
      virtualTour: data.virtual_tour,
      yearBuilt: data.year_built,
      zipcode: data.zip,
      rupId: data.rupid,
      cityId: data.geo_data?.city_id,
      zipId: data.geo_data?.zip_id,
      neighborhoodId: data.geo_data?.neighborhood_id,
      features: data.features,
    };
    return listing;
  }

  private _getDataFromRealStaq(data: any) {
    return of(null).pipe(
      switchMap(() => {
        const mls = !!data.content?.mls?.length ? data.content.mls[0] : null;

        if (data && data.content && data.content.listings && data.content.listings[0]) {
          let listing = data.content.listings[0];
          if (
            listing.state.toLowerCase().includes('nj') &&
            listing.city.toLowerCase().includes('monroe') &&
            listing.city.toLowerCase().includes('township')
          ) {
            return this.getMLSDisclaimer(20);
          }
        }
        return of(mls);
      }),
      map((mls: any) => {
        let contentListings: Listing[] = [];

        if (mls) {
          this.footerService.setMlsInfo(mls.compliance_text);
          this.footerService.setMlsIcon(mls.idx_logo || mls.idx_logo_small);
        }
        for (let listing of data.content.listings) {
          contentListings.push(this._getDataFromRealStaqListing(listing, mls));
        }
        return {
          pageNumber: data.page_number + 1,
          elementsPerPage: data.elements_per_page,
          totalElements: data.total_elements,
          totalPages: data.total_pages,
          listings: contentListings,
        } as ListingModel;
      })
    );
  }

  getSimilarListingsById(id: string, size = '3', distance = '5mi', tolerance = '0.2') {
    let params = new HttpParams();
    params = params.set('size', size);
    params = params.set('distance', distance);
    params = params.set('tolerance', tolerance);
    params = params.set('view', 'detailed');

    return this._http
      .get(this.mlsSearchApiUrl + '/v1/listings/' + id + '/similar', {
        headers: this.headers,
        params,
      })
      .pipe(
        switchMap((res: any) => this._getDataFromRealStaq({ content: res })),
        catchError((error) => {
          return of(error);
        })
      );
  }

  getListingById(id: string) {
    return this._getListingById(id).pipe(
      switchMap((listing) => {
        if (!listing) return of(null);
        return forkJoin({
          listing: of(listing),
          schools: this.getSchools(listing, '10mi'),
          demographics: this.getDemographics([listing.cityId]),
          // neighborhoods: this.getNeighborhoods(listing.state, listing.city, listing.zipcode, 1),
          valuations: this.getValuation(listing.rupId),
          propertyInfo: this.getPropertyInfo(listing.rupId),
          // properties: this.getSimilarListingsById(listing.listingId!),
        });
      })
    );
  }

  private _getListingById(id: string) {
    return this._http.get(this.mlsSearchApiUrl + '/v1/listings/' + id, { headers: this.headers }).pipe(
      map((res: any) => {
        const mls = !!res.content?.mls?.length ? res.content.mls[0] : res.mls ? res.mls : null;
        return this._getDataFromRealStaqListing(res, mls);
      }),
      catchError((error) => {
        return of(error);
      })
    );
  }

  getPropertyInfo(rupId: string | undefined) {
    if (!rupId) return of(null);
    return this._http.get(this.propertiesApiUrl + '/' + rupId, { headers: this.headers }).pipe(
      map((data: any) => data.assessment),
      catchError((error) => {
        return of(error);
      })
    );
  }

  getValuation(rupId: string | undefined) {
    if (!rupId) return of(null);

    let params = new HttpParams();
    params = params.set('rupid', rupId);
    params = params.set('timeframe', '12m');
    return this._http.get(this.valuationsApiUrl, { headers: this.headers, params }).pipe(
      catchError((error) => {
        return of(error);
      })
    );
  }

  getSimilar(id: string) {
    let params = new HttpParams();
    params = params.set('view', 'detailed');
    params = params.set('size', '12');
    return this._http.get(this.mlsSearchApiUrl + id + '/similar', { headers: this.headers, params }).pipe(
      catchError((error) => {
        return of(error);
      })
    );
  }

  getNeighborhoods(state: string | undefined, city: string | undefined, zip: string | undefined, page: number) {
    if (!state || !city || !zip) return of(null);

    let params = new HttpParams();
    params = params.append('state', state);
    params = params.append('city', city);
    if (zip) params = params.append('zip', zip);
    params = params.append('page', page);
    params = params.append('sort', 'name');
    params = params.append('size', '30');
    params = params.append('minListingCount', '1');

    params = params.set('view', 'detailed');

    return this._http.get(this.neighborhoodsApiUrl, { headers: this.headers, params }).pipe(
      map((res: any) => {
        return res.items;
      }),
      catchError((error: HttpErrorResponse) => {
        return of(error);
      })
    );
  }

  /**
   * Searches for schools within a given position and distance
   */
  getSchools(listing: Listing, distance: string) {
    if (!listing.latitude || !listing.longitude) return of(null);

    let params = new HttpParams();
    params = params.append('position', listing.latitude + ',' + listing.longitude);
    params = params.append('distance', distance);
    // params = params.append('size', '50');
    // params = params.append('instructionalLevel', 'elementary,middle,high');
    params = params.append('groupSize', '8');
    params = params.append('sort', '+distance');
    params = params.append('view', 'summary');

    return this._http.get(this.schoolApiUrl, { headers: this.headers, params }).pipe(
      map((response: any) => {
        let schoolData: any = [];
        response?.forEach((r: any) => {
          let schools: any[] = [];
          r.schools.forEach((s: any) => {
            // Do not add school with no data
            if (!s.student_teacher_ratio) return;
            const distance = this.calculateDistance(
              Number(listing.latitude),
              Number(listing.longitude),
              Number(s.location[1] || 0),
              Number(s.location[0] || 0),
              'M'
            );
            const students =
              s.enroll_pk +
              s.enroll_kg +
              s.enroll_g01 +
              s.enroll_g02 +
              s.enroll_g03 +
              s.enroll_g04 +
              s.enroll_g05 +
              s.enroll_g06 +
              s.enroll_g07 +
              s.enroll_g08 +
              s.enroll_g09 +
              s.enroll_g10 +
              s.enroll_g11 +
              s.enroll_g12;
            let gradeLow = s.grade_low;
            let gradeHigh = s.grade_high;
            if (s.grade_low == s.grade_high) {
              gradeLow = s.grade_low;
              gradeHigh = '';
            }
            schools.push({
              name: s.school_nm,
              students,
              location: s.location,
              distance,
              studentTeacherRatio: s.student_teacher_ratio,
              gradeLow,
              gradeHigh,
              address: `${s.addr_line_1} ${s.city}, ${s.state_cd}`,
              url: encodeURIComponent(
                listing.address + ',' + listing.city + ' ' + listing.state + ', ' + listing.zipcode
              ),
            });
          });
          schoolData.push({
            instructionalLevel: r.instructional_level,
            schools,
          });
        });
        let sortObj: any = {
          Primary: 0,
          Elementary: 1,
          'Combined Elementary/Secondary': 2,
          Middle: 3,
          Secondary: 4,
          High: 5,
          Other: 6,
        };
        schoolData.sort((a: any, b: any) => sortObj[a.instructionalLevel] - sortObj[b.instructionalLevel]);
        return schoolData;
      }),
      catchError((error) => {
        return of(error);
      })
    );
  }

  calculateDistance(lat1: number, lon1: number, lat2: number, lon2: number, unit: 'M' | 'K' | 'N'): number {
    if ((!lat1 || !lon1 || !lat2 || !lon2) && lat1 == lat2 && lon1 == lon2) {
      return 0;
    } else {
      var radlat1 = (Math.PI * lat1) / 180;
      var radlat2 = (Math.PI * lat2) / 180;
      var theta = lon1 - lon2;
      var radtheta = (Math.PI * theta) / 180;
      var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (dist > 1) {
        dist = 1;
      }
      dist = Math.acos(dist);
      dist = (dist * 180) / Math.PI;
      dist = dist * 60 * 1.1515;
      if (unit == 'K') {
        dist = dist * 1.609344;
      }
      if (unit == 'N') {
        dist = dist * 0.8684;
      }
      // Rounds to 1 decimal place
      return Math.round(dist * 10) / 10;
    }
  }

  /**
   * Searches for a listing's demographics given an array of city_id
   */
  getDemographics(ids: string[] | undefined | (string | undefined)[]) {
    if (!ids || (ids && ids.length == 0)) return of(null);
    let params = new HttpParams();
    params = params.append('ids', ids.join(','));
    params = params.append('view', 'detailed');

    return this._http.get(this.demographicsApiUrl, { headers: this.headers, params }).pipe(
      catchError((error) => {
        return of(error);
      })
    );
  }

  getListings() {
    let params = new HttpParams();
    let _urlObject = this.urlParserService.urlObject;

    for (let key in _urlObject) {
      if (key != 'zoom') {
        if (key == 'page') {
          const pageNumber = parseInt(_urlObject[key]) - 1;
          params = params.append('number', pageNumber.toString());
        } else params = params.append(key, _urlObject[key]);
      }
    }
    params = params.append('size', '20');

    return this._http
      .get(this.mlsSearchApiUrl + '/v1/listings', {
        headers: this.headers,
        params,
      })
      .pipe(
        switchMap((res: any) => this._getDataFromRealStaq(res)),
        catchError((error) => throwError(error))
      );
  }

  getNearByListings(latitude: string | number, longitude: string | number) {
    let params = new HttpParams();
    params = params.set('position', latitude + ',' + longitude);
    params = params.set('distance', this.distance);
    params = params.set('size', '10');

    return this._http
      .get(this.mlsSearchApiUrl + '/v1/listings', {
        headers: this.headers,
        params,
      })
      .pipe(
        switchMap((res: any) => this._getDataFromRealStaq(res)),
        catchError((error) => {
          return of(error);
        })
      );
  }

  getListingsWithQuery(queryParams: any) {
    let params = new HttpParams();
    for (let key in queryParams) {
      params = params.set(key, queryParams[key]);
    }
    params = params.set('size', '20');

    return this._http
      .get(this.mlsSearchApiUrl + '/v1/listings', {
        headers: this.headers,
        params,
      })
      .pipe(
        switchMap((res: any) => this._getDataFromRealStaq(res)),
        catchError((error) => {
          return of(error);
        })
      );
  }

  getMLSDisclaimer(mlsid: number | string) {
    return this._http
      .get(environment.MLS_API_BASE_URL + '/api/mls/v1/mls/' + mlsid, {
        headers: this.headers,
      })
      .pipe(
        catchError((error) => {
          return of(error);
        })
      );
  }

  searchLocations(input: string) {
    let params = new HttpParams();
    params = params.set('size', '10');
    params = params.set('entities', 'city,zip');
    params = params.set('input', input);

    return this._http
      .get(environment.MLS_API_BASE_URL + '/api/typeahead/v1/autocomplete', {
        headers: this.headers,
        params,
      })
      .pipe(
        catchError((error) => {
          return of(error);
        })
      );
  }

  searchAddresses(input: string) {
    let params = new HttpParams();
    params = params.set('size', '10');
    params = params.set('entities', 'address');
    params = params.set('input', input);

    return this._http
      .get(environment.MLS_API_BASE_URL + '/api/typeahead/v1/autocomplete', {
        headers: this.headers,
        params,
      })
      .pipe(
        catchError((error) => {
          return of(error);
        })
      );
  }

  getFavoritedListings(ids: string[], sort: string) {
    let params = new HttpParams();
    params = params.set('listings', ids.toString()); // TODO verify this works
    params = params.set('sort', sort);

    return of(null).pipe(
      switchMap(() => {
        if (ids.length == 0) return of(null);
        return this._http
          .get(this.mlsSearchApiUrl + '/v1/listings', {
            headers: this.headers,
            params,
          })
          .pipe(switchMap((res: any) => this._getDataFromRealStaq(res)));
      })
    );
  }
}
