import { Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs';

/**
 * Handles media for Citi branded site
 *
 * DO NOT IMPORT THIS FILE, ONLY IMPORT media.service.ts
 *
 * File replacements handle the injection of this service when building with the corresponding configuration
 */
@Injectable({
  providedIn: 'root',
})
export class MediaService {
  constructor(private sanitizer: DomSanitizer) {}

  private currentYear = new Date().getFullYear();

  sanitize(data: any) {
    return this.sanitizer.sanitize(SecurityContext.HTML, data);
  }

  SITE = new BehaviorSubject<string>('Citi');

  BANK_LOGO = 'assets/team/citi_logo_tm.png';

  STATE_SELECTOR = new BehaviorSubject<boolean>(true);
  CITI_FOOTER = new BehaviorSubject<boolean>(true);

  LOGO_HEADER = new BehaviorSubject<string>('assets/images/volly-hc-color.png');

  TEXT_HERO_H1 = new BehaviorSubject<string>('Start your Journey with Home Captain');
  TEXT_HERO_BODY = new BehaviorSubject<string>(
    `Citi has also teamed up with Home Captain to help you with your home search. With Home Captain, you can search properties or estimate how much your home is. Home Captain’s Concierge team can even match you with a real estate agent in your area. Together, Citi and Home Captain will work together as a team throughout the entire mortgage process, providing you with the personal touch you deserve and the resources you'll need to make your new home dreams come true.`
  );
  TEXT_HOW_IT_WORKS_HEADER = new BehaviorSubject<string>(
    `Take the stress out of home buying and selling. Your Citi and Home Captain team are with you each step of the way!`
  );

  CONCIERGE_EMAIL_URL = 'mailto:citi.jordan@homecaptain.com';
  CONCIERGE_EMAIL = 'citi.jordan@homecaptain.com';

  FOOTER_DISCLAIMER_TEXT = new BehaviorSubject<string>(
    `Home Captain Services are provided and operated by Home Captain Inc. Real estate agent/broker services and Citi Mortgage lending services are independently provided, and this advertisement should not be considered an endorsement of each other’s services. * You are not required to use Citi Mortgage as your mortgage lender.`
  );

  TEAM_CONTACT_ME_PHONE = '1-800-Mortgage';

  TEAM_PAGE_DISCLAIMER = new BehaviorSubject<string>(
    `By selecting “Submit” and providing us your telephone number and email address, you are providing express “written” consent for Home Captain, Inc. as well as its authorized third parties, and Citibank, N.A. and its authorized third parties to contact you (including through automated means, such as autodialing, text where SMS/MMS may apply, and pre-recorded messaging) at the number and/or email you’ve provided to offer and/or advertise its products and services, even if your telephone number is currently listed on any internal, state, or national Do Not Call list. You are not required to provide this consent as a condition of purchasing any property, goods, or services. You may contact Home Captain, Inc. at 1-887-847-4032 to discuss services offered. You may contact Citibank, N.A. to discuss mortgage and/or home equity products and services by calling 1-800-Mortgage."`
  );
  TEAM_PAGE_COPYRIGHT = new BehaviorSubject<string>(
    `Copyright ${this.currentYear}. Citibank, N.A. NMLS # 412915. Member FDIC and Equal House Lender. Citi, Citi and Arc Design and other marks used herein are service marks of Citigroup, Inc. or its affiliates, used and registered throughout the world.`
  );
}
