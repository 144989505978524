export default {
  // MLS_API_BASE_URL: 'https://services-beta.realigned.co',
  // MLS_API_KEY: '2c2ce961abd14b33b8d0bad2143c2b43',
  MLS_API_BASE_URL: 'https://services.realigned.co',
  MLS_API_KEY: '30cf18548eb64d77991acd123a41dc3c',

  GOOGLE_MAP_API_KEY: 'AIzaSyDfrKsJRPduHZJ3_lW0MBjJBmls1nMiqPA',
  STATIC_MAP_KEY: 'AIzaSyA23jK0_OMWEwoo4CS-LLtB-6Xg3RkajKk',
  STREET_VIEW_MAP_KEY: 'AIzaSyBTJmRmbsPW93Ny6SraxACIZqvlDBbiwGA',

  API_TYPE: 'REALSTAQ',
  HUB_API_KEY: '8970c55b-4897-439c-a147-4cbc2e88433f',

  GOOGLE_RECAPCHA_SITE_KEY: '6LdYuxUaAAAAAHNb47DjQSa0YFHxYAR0r87cLnhC',
  GOOGLE_RECAPCHA_V3_SITE_KEY: '6LcmjOAaAAAAAA2s7Py7JP_68gbbYDOxqn6r7ohS',

  DEFAULT_LATITUDE: 39.8097343,
  DEFAULT_LONGITUDE: -98.5556199,

  FULLPAGE_KEY: 'FE99519C-58104177-A75DCDCF-7D53FD48',

  CMA_LIVE_BASE_URL: 'https://api.icmalive.com/generateiCMA',
  CMA_ACCESS_TOKEN: 'c5d14f7f6b196b598c6cc5761f7072f7ca18aa78ce9852b7',

  ENABLED_CMA: true,

  LISTHUB: 'LISTHUB',
  REALSTAQ: 'REALSTAQ',
  LISTHUB_ID: '001',
  REALSTAQ_ID: '002',
};
