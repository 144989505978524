import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { catchError, of, map, switchMap, throwError } from 'rxjs';
import { RecaptchaErrorParameters, ReCaptchaV3Service } from 'ng-recaptcha';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { environment } from 'src/environments/environment';
import { AuthException } from 'src/app/core/enums/auth.enum';
import { AuthService } from 'src/app/core/services/auth.service';

import { LoanOfficerService } from 'src/app/core/services/loan-officer.service';
import { SearchService } from 'src/app/core/services/search.service';
import { FormValidationService } from 'src/app/core/services/form-validation.service';
import { ModalService } from 'src/app/core/services/modal.service';
import { Unsubscriber } from 'src/app/core/extenders/unsubscriber';
import { RoutingService } from 'src/app/core/services/routing.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'hch-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent extends Unsubscriber implements OnInit, OnDestroy {
  loginForm: FormGroup;
  modalRef?: BsModalRef;
  submitted = false;
  showPassword = false;
  authErrMsg = '';
  verifyErrMsg = '';
  pwdErrMsg = '';

  notConfirmed = false;

  baseUrl = environment.AUTHAPI_BASE_URL;

  isRobot = true;
  recaptchaToken = '';
  resendingCode = 'Resend';
  recaptchaSubscription = null;

  @Input() icon: string = 'assets/images/volly-hc-color.png';
  @Input() title: string = '';
  @Input() description: string = '';

  @Output() closeEvent = new EventEmitter();
  @Output() changeCategory = new EventEmitter();

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.key === 'Escape') this.close();
  }

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private modalService: ModalService,
    private bsModalRef: BsModalRef,
    private loanOfficerService: LoanOfficerService,
    private routingService: RoutingService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private formValidationService: FormValidationService
  ) {
    super();

    this.loginForm = this.fb.group({
      email: ['', Validators.compose([Validators.required, this.formValidationService.emailValidator()])],
      password: ['', Validators.required],
      verifyCode: [' ', Validators.required],
    });
  }

  ngOnInit() {}

  get email() {
    return this.loginForm.get('email');
  }
  get password() {
    return this.loginForm.get('password');
  }
  get verifyCode() {
    return this.loginForm.get('verifyCode');
  }

  close() {
    this.bsModalRef.hide();
    document.getElementById('sign-in-btn')?.focus();
  }

  forgotPassword() {
    this.bsModalRef.hide();
    setTimeout(() => {
      this.modalService.showForgotPasswordModal();
    }, 250);
  }

  createAccount() {
    this.bsModalRef.hide();
    setTimeout(() => {
      this.modalService.showRegisterModal();
    }, 250);
  }

  login() {
    this.submitted = true;
    this.authErrMsg = '';
    this.pwdErrMsg = '';
    this.notConfirmed = false;

    this.addSubscription = of(null)
      .pipe(
        switchMap(() => this.recaptchaV3Service.execute('signinAction')),
        switchMap((token) => {
          const email = this.email?.getRawValue();
          const password = this.password?.getRawValue();
          return this.authService.signInWithEmail({ email, password, token });
        }),
        // switchMap(() => this.searchService.loadTempData()),
        switchMap(() => {
          this.close();
          return of(null);
        }),
        catchError((error: any) => {
          console.error('ERROR logging in user', error);
          if (error.msg === AuthException.EMAIL_NOT_VERIFIED) {
            this.authErrMsg = 'Your email is unverified yet. Please verify.';
            this.notConfirmed = true;
            this.loginForm.patchValue({ verifyCode: '' });
          } else if (error.msg === AuthException.USER_DOES_NOT_EXIST) {
            this.pwdErrMsg = 'Your email is incorrect.';
          } else if (error.msg === AuthException.WRONG_PASSWORD) {
            this.pwdErrMsg = 'Password is incorrect.';
          } else if (error.msg === AuthException.USERNAME_OR_PASSWORD_INCORRECT) {
            this.authErrMsg = 'Email address or password is incorrect.';
          }
          this.submitted = false;
          return throwError(() => error);
        })
      )
      .subscribe();
  }

  resolved(captchaResponse: string) {
    this.isRobot = false;
    this.recaptchaToken = captchaResponse;
  }

  public onError(errorDetails: RecaptchaErrorParameters): void {
    this.isRobot = true;
  }

  togglePassword($event: any) {
    $event.preventDefault();
    this.showPassword = !this.showPassword;
  }

  // async verify() {
  //   this.submitted = true;
  //   this.authErrMsg = '';
  //   this.verifyErrMsg = '';
  //   this.pwdErrMsg = '';
  //   try {
  //     let data = await this.authService.verifyEmail(this.loginForm.value.email, this.loginForm.value.verifyCode.trim());

  //     this.submitted = false;
  //     this.notConfirmed = false;
  //   } catch (err) {
  //     this.verifyErrMsg = 'Invalid verification code';
  //     this.submitted = false;
  //   }
  // }

  // async resendCode() {
  //   try {
  //     this.resendingCode = 'Sending';
  //     await this.authService.resendVerificationCode(this.loginForm.value.email);
  //     this.resendingCode = 'Sent';
  //     setTimeout(() => {
  //       this.resendingCode = 'Resend';
  //     }, 5000);
  //   } catch (err) {
  //     this.resendingCode = 'Resend';
  //   }
  // }

  /**
   * @param url The base url to redirect to
   * @returns The url to set the window.location.href
   */
  oauthLogin(url: string) {
    const nmls = this.routingService.nmls.getValue();
    const staticQueryParams = this.routingService.staticQueryParams.getValue();
    return of(null).pipe(
      switchMap(() => {
        if (nmls || staticQueryParams['id'] || staticQueryParams['campaignid']) {
          return this.loanOfficerService.getLoanOfficerByNMLS(nmls).pipe(
            map((loanOfficer) => {
              if (loanOfficer) {
                const userMR = {
                  nmls,
                  sfid: staticQueryParams['id'],
                  campaignId: staticQueryParams['campaignid'],
                  loEmail: loanOfficer.email,
                  loName: loanOfficer.name,
                };
                return url + '&userMR=' + window.btoa(JSON.stringify(userMR));
              }
              return url;
            })
          );
        }
        return of(url);
      })
    );
  }

  googleLogin() {
    const url = `${this.baseUrl}/google-login?callback=${window.btoa(window.location.href)}`;
    this.addSubscription = this.oauthLogin(url)
      .pipe(
        map((link) => {
          window.location.href = link;
        })
      )
      .subscribe();
  }

  facebookLogin() {
    const url = `${this.baseUrl}/facebook-login?callback=${window.btoa(window.location.href)}`;
    this.addSubscription = this.oauthLogin(url)
      .pipe(
        map((link) => {
          window.location.href = link;
        })
      )
      .subscribe();
  }

  openPrivacyPolicyModal() {
    this.modalRef = this.modalService.showPoliciesModal();

    this.addSubscription = this.modalRef.onHidden?.subscribe(() => {
      setTimeout(() => {
        document.getElementById('pp-sign-in-btn')?.focus();
      }, 0);
    });
  }
}
