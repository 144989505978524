import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Unsubscriber } from 'src/app/core/extenders/unsubscriber';

import { AuthService } from 'src/app/core/services/auth.service';
import { BuyService } from 'src/app/buy/buy.service';
import { ModalService } from 'src/app/core/services/modal.service';
import { RoutingService } from 'src/app/core/services/routing.service';
import { FavoritesService } from 'src/app/favorites/favorites.service';

@Component({
  selector: 'hch-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
})
export class PageHeaderComponent extends Unsubscriber implements OnInit {
  currentRoute: string = '';

  activatedRoute: any;
  // paramsMap: any = {};

  constructor(
    public authService: AuthService,
    public favoritesService: FavoritesService,
    public routingService: RoutingService,
    private route: ActivatedRoute,
    private modalService: ModalService
  ) {
    super();
  }

  ngOnInit(): void {
    this.addSubscription = this.route.url.subscribe((urls) => {
      for (let url of urls) {
        if (url.path.includes('buy')) {
          this.currentRoute = 'buy';
          break;
        } else if (url.path.includes('homevalue')) {
          this.currentRoute = 'homevalue';
          break;
        } else if (url.path.includes('team')) {
          this.currentRoute = 'team';
          break;
        } else if (url.path.includes('favorite')) {
          this.currentRoute = 'favorite';
          break;
        } else if (url.path.includes('affordability')) {
          this.currentRoute = 'affordability';
          break;
        }
      }
    });
  }

  login() {
    this.modalService.showLoginModal();
  }

  register() {
    this.modalService.showRegisterModal('Create Your Free Account');
  }
}
