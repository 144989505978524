<div cdkTrapFocus class="auth-modal__content" [ngClass]="{ 'auth-content': step < 2 }">
  <button class="close-btn" (click)="close()">
    <img src="assets/icons/close.svg" alt="" />
  </button>
  <div class="step" *ngIf="step < 2">
    <h5 class="auth-title">Create A New Password</h5>
    <form class="auth-form" [formGroup]="passwordForm">
      <div class="password-field">
        <hch-input
          field="password"
          placeholder="New Password"
          formControlName="newPassword"
          [type]="bShowNewPassword ? 'text' : 'password'"
          [dirty]="newPassword?.['dirty']"
          [errorRequired]="newPassword?.errors?.['required']"
          [maxlength]="24"
        ></hch-input>
        <div class="visible-btn" (click)="toggleNewPassword($event)">
          <img *ngIf="!bShowNewPassword" src="assets/icons/invisible.svg" alt="Make password invisible" />
          <img *ngIf="bShowNewPassword" src="assets/icons/visible.svg" alt="Make password visible" />
        </div>
      </div>
      <div class="password-strength" *ngIf="newPassword?.['value'].length > 0">
        <div class="strength">
          <div class="strength-indications">
            <div
              class="indication"
              [ngClass]="indicationStyle"
              [ngStyle]="{ visibility: strength >= 0 ? 'visible' : 'hidden' }"
            ></div>
            <div
              class="indication"
              [ngClass]="indicationStyle"
              [ngStyle]="{ visibility: strength >= 1 ? 'visible' : 'hidden' }"
            ></div>
            <div
              class="indication"
              [ngClass]="indicationStyle"
              [ngStyle]="{ visibility: strength >= 2 ? 'visible' : 'hidden' }"
            ></div>
            <div
              class="indication"
              [ngClass]="indicationStyle"
              [ngStyle]="{ visibility: strength >= 3 ? 'visible' : 'hidden' }"
            ></div>
          </div>
          <span class="text-s" [ngClass]="indicationStyle" *ngIf="!newPassword?.errors?.['invalid']">
            {{ strengthLegend[strength] }}
          </span>
        </div>
        <p class="text-s" *ngIf="newPassword?.errors?.['invalid']" style="font-weight: bold">
          Contains an invalid character {{ newPassword?.errors?.['invalidChars'] }}
        </p>
        <p class="text-s" *ngIf="!newPassword?.errors?.['invalid'] && newPassword?.errors?.['needLowercaseChar']">
          Include at least one lowercase letter
        </p>
        <p class="text-s" *ngIf="!newPassword?.errors?.['invalid'] && newPassword?.errors?.['needUppercaseChar']">
          Include at least one uppercase letter
        </p>
        <p class="text-s" *ngIf="!newPassword?.errors?.['invalid'] && newPassword?.errors?.['needNumber']">
          Include at least one number
        </p>
        <p class="text-s" *ngIf="!newPassword?.errors?.['invalid'] && newPassword?.errors?.['needSpecialChar']">
          Include at least one valid special character !@#$%^&*()-_=+<>?
        </p>
        <p class="text-s" *ngIf="!newPassword?.errors?.['invalid'] && newPassword?.errors?.['needMinLength']">
          Make the password at least 10 characters long
        </p>
      </div>
      <div class="password-field" *ngIf="step > 0">
        <hch-input
          field="password"
          placeholder="Confirm Password"
          formControlName="confirmPassword"
          [type]="bShowConfirmPassword ? 'text' : 'password'"
          [dirty]="confirmPassword?.['dirty']"
          [errorRequired]="confirmPassword?.errors?.['required']"
          [errorMessage]="passwordMustMatchError || authErrMsg"
          [maxlength]="24"
        ></hch-input>
        <div class="visible-btn" (click)="toggleConfirmPassword($event)">
          <img *ngIf="!bShowConfirmPassword" src="assets/icons/invisible.svg" alt="Hide password" />
          <img *ngIf="bShowConfirmPassword" src="assets/icons/visible.svg" alt="Show password" />
        </div>
      </div>
      <!-- <div class="form-group password-field" *ngIf="step > 0">
        <input
          class="text-s"
          [type]="bShowConfirmPassword ? 'text' : 'password'"
          placeholder="Confirm Password"
          formControlName="confirmPassword"
        />
        <label class="placeholder text-s">Confirm Password</label>
        <div class="visible-btn" (click)="toggleConfirmPassword($event)">
          <img *ngIf="bShowConfirmPassword" src="assets/icons/invisible.svg" alt="" />
          <img *ngIf="!bShowConfirmPassword" src="assets/icons/visible.svg" alt="" />
        </div>
        <div class="error text-xs" *ngIf="authErrMsg">
          <img class="alert-img" src="assets/icons/alert.svg" alt="" />
          <span class="text-xs error-text">{{ authErrMsg }}</span>
        </div> -->
      <!-- </div> -->
      <button class="hch-btn hch-btn-primary form-btn" [disabled]="disableSubmit()" (click)="onConfirm()">
        {{ submitted ? 'Confirming . . .' : 'Confirm' }}
      </button>
    </form>
  </div>
  <div class="step change-success" *ngIf="step === 2">
    <img src="assets/images/volly-hc-color.png" class="logo" alt="Home Captain logo" />
    <h5 class="auth-title">Password Changed!</h5>
    <img src="assets/images/auth/password-changed.png" class="done" alt="Password changed logo" />
    <!-- <p class="auth-desc text-s">You will receive an email shortly confirming your password change.</p> -->
    <button class="hch-btn hch-btn-secondary done-btn" (click)="close()">Close</button>
  </div>
</div>
